@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: "Space Mono", monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f0fff1;
    color: white;
}

code {
    font-family: "Space Mono", monospace;
}
